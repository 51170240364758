import { __awaiter, __decorate } from "tslib";
// / <reference types="facebook-js-sdk" />
import readyState from 'ready-state';
import { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { awaitProperty } from '@slickdeals/durango/dist/javascript/utilities/awaitProperty';
import { CorsAttribute, injectScriptTag, getAttributeValue } from '@slickdeals/durango/dist/javascript/utilities/dom';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
export const name = 'FacebookAdapter';
export var LoginStatus;
(function (LoginStatus) {
    LoginStatus["AuthorizationExpired"] = "authorization_expired";
    LoginStatus["Connected"] = "connected";
    LoginStatus["NotAuthorized"] = "not_authorized";
    LoginStatus["Unknown"] = "unknown";
})(LoginStatus || (LoginStatus = {}));
var PropertyKeys;
(function (PropertyKeys) {
    PropertyKeys["Global"] = "FB";
    PropertyKeys["Login"] = "login";
    PropertyKeys["ScriptElementId"] = "facebook-jssdk";
})(PropertyKeys || (PropertyKeys = {}));
let FacebookAdapter = class FacebookAdapter {
    /**
     * @throws Error
     */
    login(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const fb = yield this.getFacebookObject();
            return new Promise(resolve => fb.login((response) => resolve(response), options));
        });
    }
    getFacebookObject() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.facebookObject) {
                const fb = yield awaitProperty(PropertyKeys.Global, window);
                // When methods other than `init` are present, SDK has initialized.
                // See https://developers.facebook.com/docs/javascript/reference/FB.init/v10.0
                yield awaitProperty(PropertyKeys.Login, fb);
                this.facebookObject = fb;
            }
            return this.facebookObject;
        });
    }
};
FacebookAdapter = __decorate([
    injectable({ key: name, scope: InjectorScope.Singleton })
], FacebookAdapter);
export default FacebookAdapter;
const getAppId = () => {
    // Facebook recommends having the app ID in this meta tag to use Facebook Insights, so we can pull it from there.
    const appId = getAttributeValue('meta[property="fb:app_id"]', 'content');
    if (appId) {
        return appId;
    }
    throw new Error('Failed to find Facebook App ID in meta tags');
};
(() => __awaiter(void 0, void 0, void 0, function* () {
    yield readyState.domready;
    const sdk = document.getElementById(PropertyKeys.ScriptElementId);
    if (sdk === null) {
        injectScriptTag('https://connect.facebook.net/en_US/sdk.js', {
            id: PropertyKeys.ScriptElementId,
            async: true,
            crossOrigin: CorsAttribute.Anonymous,
            onload: () => {
                window.FB.init({
                    appId: getAppId(),
                    version: 'v10.0',
                    status: true,
                    cookie: true,
                    xfbml: false,
                });
            },
        });
    }
    else {
        Logger.warn('Facebook SDK already added to DOM. We should not be loading this twice on the page!');
    }
}))();

import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Tooltip from '@slickdeals/blueprint-twig/javascript/components/tooltip';
import { FacebookSignOnResponseStatus, name as facebookAuthenticationServiceName } from '../facebook/facebookAuthenticationService';
import { LoginStatus, name as facebookAdapterName } from '../facebook/facebookAdapter';
import '@slickdeals/blueprint-twig/javascript/components/iconButton';
import '../../scss/patterns/facebookSocialSignOnButton.scss';
export const name = 'FacebookSocialSignOnButton';
export const dependencies = Object.assign(Object.assign({}, Injector.createDependency(facebookAdapterName, InjectorScope.Singleton)), Injector.createDependency(facebookAuthenticationServiceName, InjectorScope.Singleton));
let FacebookSocialSignOnButton = class FacebookSocialSignOnButton extends BlueprintComponent {
    constructor(fb, facebookAuthService, options) {
        super(name, options);
        this.fb = fb;
        this.facebookAuthService = facebookAuthService;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            this.element.addEventListener('click', this.onClick.bind(this));
        });
    }
    /**
     * Retrieves access token from Facebook and passes it to our backend to login or register the user
     */
    onClick() {
        return __awaiter(this, void 0, void 0, function* () {
            this.element.disabled = true;
            if (this.tooltip) {
                this.tooltip.destroy(true);
            }
            try {
                const facebookResponse = yield this.fb.login({ scope: 'public_profile,email' });
                if (facebookResponse.status === LoginStatus.Connected) {
                    const sdResponse = yield this.facebookAuthService.signOn(facebookResponse.authResponse.accessToken, this.options.actionSource, this.options.regUrl);
                    this.handleSdResponse(sdResponse);
                }
            }
            catch (e) {
                Logger.error(`Facebook Login error: ${e.message}`);
            }
            this.element.disabled = false;
        });
    }
    handleSdResponse(sdResponse) {
        if (sdResponse.status === FacebookSignOnResponseStatus.Success) {
            if (sdResponse.redirect) {
                Redirector.redirect(sdResponse.redirect);
            }
            else {
                window.location.reload();
            }
            return;
        }
        if (sdResponse.status === FacebookSignOnResponseStatus.SsoLink) {
            Redirector.redirectAsPost(sdResponse.redirect, sdResponse.redirectPost);
            return;
        }
        if (sdResponse.status === FacebookSignOnResponseStatus.Retry) {
            this.showTooltip('A network error occurred. Please try again.');
            return;
        }
        if (sdResponse.status === FacebookSignOnResponseStatus.PermissionRequired) {
            this.showTooltip(sdResponse.message);
            return;
        }
        throw new Error(`Unknown response status from SD Facebook Sign-on: ${sdResponse.status}`);
    }
    showTooltip(message) {
        this.tooltip = new Tooltip({
            triggerElement: this.element,
            contents: message,
            showOnInit: true,
        });
    }
};
FacebookSocialSignOnButton = __decorate([
    injectable(name, dependencies)
], FacebookSocialSignOnButton);
export default FacebookSocialSignOnButton;
BlueprintComponent.instantiate(name);

import { __awaiter, __decorate } from "tslib";
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { Credentials, name as fetchServiceName } from '@slickdeals/blueprint-twig/javascript/fetch/fetchService';
export const name = 'FacebookAuthenticationService';
var Endpoints;
(function (Endpoints) {
    Endpoints["SignOn"] = "sign_on";
})(Endpoints || (Endpoints = {}));
export var FacebookSignOnResponseStatus;
(function (FacebookSignOnResponseStatus) {
    FacebookSignOnResponseStatus["Success"] = "success";
    FacebookSignOnResponseStatus["Retry"] = "retry";
    FacebookSignOnResponseStatus["SsoLink"] = "ssoLink";
    FacebookSignOnResponseStatus["PermissionRequired"] = "permissionRequired";
})(FacebookSignOnResponseStatus || (FacebookSignOnResponseStatus = {}));
export const dependencies = Object.assign({}, Injector.createDependency(fetchServiceName, InjectorScope.Singleton));
let FacebookAuthenticationService = class FacebookAuthenticationService {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    signOn(facebookAccessToken, actionSource, regUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchService.fetch(name, Endpoints.SignOn, {
                facebookAccessToken,
                actionSource,
                regUrl,
            }, {
                credentials: Credentials.SameOrigin,
                headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            });
        });
    }
};
FacebookAuthenticationService = __decorate([
    injectable({ key: name, dependencies, scope: InjectorScope.Singleton })
], FacebookAuthenticationService);
export default FacebookAuthenticationService;

import { __awaiter, __decorate } from "tslib";
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import '../../scss/patterns/scrollToTopButton.scss';
/** The name of the module */
export const name = 'ScrollToTopButton';
export var ClassNames;
(function (ClassNames) {
    ClassNames["JavaScript"] = "js-scrollToTopButton";
    ClassNames["Default"] = "bp-p-scrollToTopButton";
})(ClassNames || (ClassNames = {}));
let ScrollToTopButton = class ScrollToTopButton extends BlueprintComponent {
    /**
     * Create a ScrollToTopButton UI component
     * @param options Data and options to initialize the component with
     */
    constructor(options) {
        super(name, options);
        this.options = options;
    }
    /**
     * Perform any necessary initialization for the component
     */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            this.element.addEventListener('click', () => {
                document.documentElement.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        });
    }
};
ScrollToTopButton = __decorate([
    injectable(name)
], ScrollToTopButton);
export default ScrollToTopButton;
BlueprintComponent.instantiate(name);

import LazyLoad from '@slickdeals/blueprint-twig/javascript/core/lazyLoad';
import '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/linkableButton';
import '../patterns/extensionPromoBanner';
import '../patterns/paginatedGrid';
import '../patterns/scrollableCardGrid';
import '../patterns/scrollToTopButton';
import '../patterns/topCouponCard';
import '../patterns/readMoreButton';
import '../patterns/newsletterRegistrationForm';
import '../patterns/inlineResponse';
import '../../scss/pages/couponHub.scss';
new LazyLoad(); // eslint-disable-line no-new

var NewsletterRegistrationForm_1;
import { __awaiter, __decorate } from "tslib";
import ComponentRegistry from '@slickdeals/durango/dist/javascript/core/componentRegistry';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Form, { FormSubmissionEvents, name as formName } from '@slickdeals/blueprint-twig/javascript/components/form';
import Image from '@slickdeals/blueprint-twig/javascript/components/image';
import '@slickdeals/blueprint-twig/javascript/components/inlineForm';
import '@slickdeals/blueprint-twig/javascript/components/passwordInput';
import './facebookSocialSignOnButton';
import '@slickdeals/blueprint-twig/javascript/components/icon';
import '../../scss/patterns/newsletterRegistrationForm.scss';
export const name = 'NewsletterRegistrationForm';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Title"] = "js-newsletterRegistrationForm_title";
    ClassNames["EmailForm"] = "js-newsletterRegistrationForm_emailForm";
    ClassNames["PasswordForm"] = "js-newsletterRegistrationForm_passwordForm";
    ClassNames["BlueberrySuccess"] = "bp-p-newsletterRegistrationForm_blueberry--success";
})(ClassNames || (ClassNames = {}));
let NewsletterRegistrationForm = NewsletterRegistrationForm_1 = class NewsletterRegistrationForm extends BlueprintComponent {
    constructor(options) {
        super(name, options);
    }
    static create(options) {
        return new NewsletterRegistrationForm_1(options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            this.emailForm = this.element.querySelector(`.${ClassNames.EmailForm}`);
            this.emailForm.addEventListener('submit', this.onFormSubmit.bind(this));
            this.titleElement = this.element.querySelector(`.${ClassNames.Title}`);
            if (this.options.isBlueberryFlow) {
                // TODO: Enable once backend is hooked up
                // this.emailForm.addEventListener('submit', this.handleBlueberryFormSubmission);
                // TODO: Temporary - remove once backend is hooked up
                const tempFormButton = this.element.querySelector('.bp-c-inlineForm_submitButton');
                tempFormButton.addEventListener('click', this.handleBlueberryFormSubmission.bind(this));
                return;
            }
            this.passwordForm = this.element.querySelector(`.${ClassNames.PasswordForm}`);
            this.emailForm.addEventListener(FormSubmissionEvents.Success, this.onEmailFormSuccess.bind(this));
            this.passwordForm.addEventListener('submit', this.onFormSubmit.bind(this));
            this.passwordForm.addEventListener(FormSubmissionEvents.Success, this.onPasswordFormSuccess.bind(this));
        });
    }
    handleBlueberryFormSubmission() {
        this.element.classList.add(ClassNames.BlueberrySuccess);
        this.titleElement.textContent = 'You have successfully signed up for email newsletters!';
        ComponentRegistry.get(this.emailForm, formName).hide();
        const image = Image.createElement({
            src: '/image-pool/couponsSubdomain/person1.svg',
        });
        this.element.appendChild(image);
    }
    onFormSubmit() {
        this.clearErrorMessage();
    }
    onEmailFormSuccess(event) {
        const resData = event.detail;
        if (resData.error && resData.message) {
            this.showErrorMessage(resData.message);
            return;
        }
        if (resData.skipRegistration) {
            this.showSuccessMessage('Thank you for signing up for our newsletter.');
            return;
        }
        this.copyEmailToPasswordForm();
        this.showPasswordForm();
    }
    onPasswordFormSuccess(event) {
        const resData = event.detail;
        if (resData.status === 'error' && resData.message) {
            this.showErrorMessage(resData.message);
            return;
        }
        this.showSuccessMessage('Thank you for registering! Logging in now...');
        Redirector.redirect(resData.successUrl);
    }
    copyEmailToPasswordForm() {
        const email = this.emailForm.querySelector('[name="email_address"]').value;
        this.passwordForm.querySelector('[name="email"]').value = email;
    }
    showPasswordForm() {
        this.clearErrorMessage();
        this.titleElement.textContent = 'Get more from Slickdeals by becoming a member today.';
        ComponentRegistry.get(this.emailForm, formName).hide();
        ComponentRegistry.get(this.passwordForm, formName).show();
    }
    showSuccessMessage(message) {
        this.clearErrorMessage();
        this.titleElement.textContent = message;
        ComponentRegistry.get(this.emailForm, formName).hide();
        ComponentRegistry.get(this.passwordForm, formName).hide();
    }
    showErrorMessage(message) {
        this.errorElement = Form.createErrorMessage(message);
        this.passwordForm.insertAdjacentElement('afterend', this.errorElement);
    }
    clearErrorMessage() {
        if (this.errorElement) {
            this.errorElement.remove();
        }
    }
};
NewsletterRegistrationForm = NewsletterRegistrationForm_1 = __decorate([
    injectable(name)
], NewsletterRegistrationForm);
export default NewsletterRegistrationForm;
BlueprintComponent.instantiate(name);
